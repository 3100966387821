.qualities {
    &__container {
        padding: 130px 15px;
    }

    &__title {
        margin: 0;
        margin-bottom: 50px;
    }

    &__list {
        display: flex;
        justify-content: space-between;
        
    }

    &__item {
        flex-basis: 16%;
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    &__image {
        width: 100px;
        height: 100px;
        margin-bottom: 20px;
        &>img {
            width: 100%;
            height: 100%;
        }
    }

    &__text {
        text-align: center;
        font: 300 20px / 31px var(--font-family);
    }
}

@include mobile-small {
    .qualities__list {
        flex-wrap: wrap;
        justify-content: center;
    }
    .qualities__item {
        flex-basis: 33%;
    }
}