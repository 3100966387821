//
.header {
  background-image: url('../img/bg.webp');
  position: relative;
  background-size: cover;
  min-height: 800px;
  height: 100vh;

  &__logo {
    padding-top: 40px;
  }

  &__bg-car {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

@include tablet{
  .header__bg-car {
    right: -20%;
  }
}

@include mobile {
  .header__bg-car {
    right: -50%;
  }
}

@include mobile-small {
  .header__logo {
    text-align: center;
  }
  
  .header__bg-car {
    right: 0;
  }
}