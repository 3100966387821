// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  --color-black: #000;
  --color-white: #fff;
  --color-accent: #c21b24;

  // шрифты
  --font-family: Arial, Helvetica, sans-serif;
  --higt-font: 500 50px / 140% var(--font-family);
}
