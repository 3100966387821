.modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.6);
    z-index: 1000;

    &_content {
        background-color: #fefefe;
        text-align: center;
        margin: 15% auto;
        padding: 20px;
        border: 1px solid #888;
        width: 33%;
        z-index: 99999;

        .close_modal_window {
            color: #aaa;
            float: right;
            font-size: 28px;
            font-weight: bold;
            cursor: pointer;
        }
    }
    
}