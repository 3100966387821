:root {
  --color-black: #000;
  --color-white: #fff;
  --color-accent: #c21b24;
  --font-family: Arial, Helvetica, sans-serif;
  --higt-font: 500 50px / 140% var(--font-family);
}

.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: var(--font-family);
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important;
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1200px;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.btn {
  color: var(--color-white);
  font-size: 16px;
  font-weight: 400;
  border-radius: 30px;
  background-color: var(--color-accent);
  padding: 10px 40px;
}

.section__title {
  text-align: center;
  font: var(--higt-font);
}
.section__title::first-letter {
  color: var(--color-accent);
}

input {
  border: none;
  outline: none;
}
input:focus {
  border: none;
}

.header {
  background-image: url("../img/bg.webp");
  position: relative;
  background-size: cover;
  min-height: 800px;
  height: 100vh;
}
.header__logo {
  padding-top: 40px;
}
.header__bg-car {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (max-width: 1024px) {
  .header__bg-car {
    right: -20%;
  }
}
@media (max-width: 768px) {
  .header__bg-car {
    right: -50%;
  }
}
@media (max-width: 576px) {
  .header__logo {
    text-align: center;
  }

  .header__bg-car {
    right: 0;
  }
}
.hero {
  position: absolute;
  top: 25%;
  left: 0;
  right: 0;
  color: var(--color-white);
}
.hero__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding-left: 110px;
}
.hero__title {
  margin: 0;
  font-size: 65px;
  font-weight: 500;
  margin-bottom: 40px;
}
.hero__text {
  margin: 0;
  font-size: 32px;
  font-weight: 200;
  line-height: 150%;
  max-width: 412px;
  margin-bottom: 30px;
}

@media (max-width: 576px) {
  .hero__container {
    padding-left: 40px;
  }
}
.qualities__container {
  padding: 130px 15px;
}
.qualities__title {
  margin: 0;
  margin-bottom: 50px;
}
.qualities__list {
  display: flex;
  justify-content: space-between;
}
.qualities__item {
  flex-basis: 16%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.qualities__image {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}
.qualities__image > img {
  width: 100%;
  height: 100%;
}
.qualities__text {
  text-align: center;
  font: 300 20px/31px var(--font-family);
}

@media (max-width: 576px) {
  .qualities__list {
    flex-wrap: wrap;
    justify-content: center;
  }

  .qualities__item {
    flex-basis: 33%;
  }
}
.services {
  position: relative;
  background-image: url("../img/bg-smoke.jpg");
  background-size: cover;
  background-position: 0 60%;
}
.services__container {
  padding-top: 50px;
  padding-bottom: 200px;
}
.services__title {
  color: var(--color-white);
  margin-bottom: 40px;
}
.services__list {
  margin-bottom: 50px;
}
.services__item {
  color: #f0f0f0;
  font-size: 22px;
  font-weight: 200;
  line-height: 43px;
  padding-left: 70px;
  display: flex;
  align-items: center;
}
.services__item::before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  background-image: url("../img/list-icon.svg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 15px;
}
.services__btn {
  padding-left: 70px;
}
.services__bg-car {
  width: 785px;
  position: absolute;
  right: 5%;
  bottom: 21%;
}

@media (max-width: 1024px) {
  .services__bg-car {
    right: -20%;
  }
}
@media (max-width: 768px) {
  .services__bg-car {
    right: -50%;
    top: 13%;
  }
}
@media (max-width: 576px) {
  .services__container {
    padding-bottom: 327px;
  }
  .services__bg-car {
    width: 100%;
    bottom: 0;
    right: 0;
    top: unset;
  }
}
.brends {
  padding: 100px 20px;
  margin-bottom: 200px;
}
.brends__title {
  margin: 0;
  margin-bottom: 150px;
}

@media (max-width: 1024px) {
  .brends {
    margin-bottom: 0;
  }
}
@media (max-width: 576px) {
  .brends__title {
    margin-bottom: 100px;
  }
}
.feedback {
  background-image: url("../img/bg-smoke.jpg");
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.feedback__container {
  max-width: 720px;
  padding: 25px 0;
}
.feedback__title {
  font-size: 42px;
  font-weight: 500;
  color: var(--color-white);
  text-align: center;
  margin-bottom: 50px;
}
.feedback__title--accent {
  color: var(--color-accent);
}
.feedback__text {
  margin-bottom: 50px;
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  color: var(--color-white);
}
.feedback__form {
  max-width: 560px;
  margin: 0 auto;
}
.feedback .form {
  width: 100%;
  padding: 10px;
}
.feedback .form__field {
  width: 100%;
  margin-bottom: 20px;
}
.feedback .form__field > input {
  width: 100%;
  padding: 0 20px;
  height: 56px;
  border-radius: 7px;
  margin-bottom: 10px;
  font-size: 16px;
}
.feedback .form__field > input:valid:not(:placeholder-shown) {
  border: 3px solid green;
}
.feedback .form__field > input:invalid:not(:placeholder-shown) {
  border: 3px solid #fa3c3c;
}
.feedback .form__field > input:invalid:not(:placeholder-shown) + .form__error {
  display: block;
}
.feedback .form__error {
  color: #fa3c3c;
  text-align: left;
  font-size: 15px;
  display: block;
  margin-top: 3px;
  display: none;
}
.feedback .form__btn {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  padding-top: 19px;
  padding-bottom: 19px;
  margin-bottom: 30px;
}
.feedback__privacy {
  color: var(--color-white);
  text-align: center;
}

@media (max-width: 576px) {
  .feedback {
    padding-top: 40px;
    padding-bottom: 40px;
    height: auto;
  }
}
.price__container {
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 25px;
}
.price__subtitle {
  padding: 10px 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 2px;
  background-color: var(--color-black);
  color: var(--color-white);
}
.price__table {
  width: 100%;
  border-collapse: collapse;
}
.price__table td {
  height: 30px;
  letter-spacing: 3px;
  font-size: 18px;
}
.price__table td:not(:first-child) {
  text-align: right;
  white-space: nowrap;
}
.price__footer {
  background-color: var(--color-black);
  color: var(--color-white);
  letter-spacing: 3px;
  padding: 15px 20px;
  padding-left: 40px;
}
.price__footer-item {
  padding: 10px 0;
}

@media (max-width: 576px) {
  .price__table tr {
    border-bottom: 1px solid #000;
  }
  .price__table td {
    height: 40px;
    font-size: 14px;
    letter-spacing: 2px;
    border-bottom: 1px solid #000;
  }
  .price__footer {
    font-size: 14px;
  }
}
.contacts {
  height: 100vh;
}
.contacts__container {
  display: flex;
  height: 100%;
}
.contacts__left {
  width: 50%;
  height: inherit;
}
.contacts__map {
  width: 100%;
  height: 100%;
}
.contacts__right {
  width: 50%;
  background-color: #0f0f0f;
  padding: 90px;
  display: flex;
  align-items: center;
  color: var(--color-white);
}
.contacts__title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 45px;
}
.contacts__phone {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 30px;
}
.contacts__phone > a {
  color: var(--color-white);
}
.contacts__address {
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  margin-bottom: 38px;
}

@media (max-width: 576px) {
  .contacts__container {
    flex-direction: column;
  }
  .contacts__left {
    width: 100%;
  }
  .contacts__right {
    width: 100%;
  }
}
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}
.modal_content {
  background-color: #fefefe;
  text-align: center;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 33%;
  z-index: 99999;
}
.modal_content .close_modal_window {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}