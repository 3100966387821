.hero {
    position: absolute;
    top: 25%;
    left: 0;
    right: 0;

    color: var(--color-white);

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        padding-left: 110px;
    }

    &__title {
        margin: 0;
        font-size: 65px;
        font-weight: 500;
        margin-bottom: 40px;
    }

    &__text {
        margin: 0;
        font-size: 32px;
        font-weight: 200;
        line-height: 150%;
        max-width: 412px;
        margin-bottom: 30px;
    }
}

@include mobile-small {
    .hero__container {
        padding-left: 40px;
    }
}