html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: var(--font-family);
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden; // если используете на сайте position: sticky - уберите эту настройку
}

.is-hidden {
  display: none !important; // stylelint-disable-line declaration-no-important
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1200px;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.btn {
  color: var(--color-white);
  font-size: 16px;
  font-weight: 400;
  border-radius: 30px;
  background-color: var(--color-accent);
  padding: 10px 40px;
}

.section__title {
  text-align: center;
  font: var(--higt-font);
  
  &::first-letter {
    color: var(--color-accent);
  }
}

input {
  border: none;
  outline: none;
  &:focus {
    border: none;
  }
}