.feedback {
    background-image: url("../img/bg-smoke.jpg");
    background-size: cover;
    min-height: 100vh;
    display: flex;
    align-items: center;

    &__container {
        max-width: 720px;
        padding: 25px 0;
    }

    &__title {
        font-size: 42px;
        font-weight: 500;
        color: var(--color-white);
        text-align: center;
        margin-bottom: 50px;

        &--accent {
            color: var(--color-accent);
        }
    }

    &__text {
        margin-bottom: 50px;
        text-align: center;
        font-size: 20px;
        font-weight: 300;
        color: var(--color-white);
    }

    &__form {
        max-width: 560px;
        margin: 0 auto;
    }
    
    .form {
        width: 100%;
        padding: 10px;
        
        &__field {
            width: 100%;
            margin-bottom: 20px;

            &>input {
                width: 100%;
                padding: 0 20px;
                height: 56px;
                border-radius: 7px;
                margin-bottom: 10px;

                font-size: 16px;
                &:valid:not(:placeholder-shown) {
                    border: 3px solid green;
                }
                &:invalid:not(:placeholder-shown) {
                    border: 3px solid #fa3c3c;
                    & + .form__error {
                        display: block;
                    }
                }
                
            }
        }
        &__error {
            color: #fa3c3c;
            text-align: left;
            font-size: 15px;
            display: block;
            margin-top: 3px;
            display: none;
        }

        &__btn {
            width: 100%;
            font-size: 16px;
            font-weight: 700;
            padding-top: 19px;
            padding-bottom: 19px;
            margin-bottom: 30px;
        }
    }

    &__privacy {
        color: var(--color-white);
        text-align: center;
    }
}

@include mobile-small {
    .feedback {
        padding-top: 40px;
        padding-bottom: 40px;
        height: auto;
    }
}