.brends {
    padding: 100px 20px;
    margin-bottom: 200px;
    &__title {
        margin: 0;
        margin-bottom: 150px;
    }
}

@include tablet {
    .brends {
        margin-bottom: 0;
    }
}

@include mobile-small {
    .brends__title {
        margin-bottom: 100px;
    }
}