.price {
    &__container {
        max-width: 800px;
        margin: 0 auto;
        margin-bottom: 25px;
    }
    &__subtitle {
        padding: 10px 0;
        text-align: center;
        text-transform: uppercase;
        font-weight: 300;
        letter-spacing: 2px;
        background-color: var(--color-black);
        color: var(--color-white);
    }

    &__table {
        width: 100%;
        border-collapse: collapse;
        &  td {
            height: 30px;
            letter-spacing: 3px;
            font-size: 18px;
        }

        & td:not(:first-child) {
            text-align: right;
            white-space: nowrap;
        }
    }   

    &__footer {
        background-color: var(--color-black);
        color: var(--color-white);
        letter-spacing: 3px;
        padding: 15px 20px;

        padding-left: 40px;

        &-item {
            padding: 10px 0;
        }
    }
}

@include mobile-small {
    .price {
        &__table {
            & tr {
                border-bottom: 1px solid #000;
            }
            & td {
                height: 40px;
                font-size: 14px;
                letter-spacing: 2px;
                border-bottom: 1px solid #000;
            }
        }

        &__footer {
            font-size: 14px;
        }        
    }
}