.contacts {
    height: 100vh;

    &__container {
        display: flex;
        height: 100%;
    }

    &__left {
        width: 50%;
        height: inherit;
    }

    &__map {
        width: 100%;
        height: 100%;
    }

    &__right {
        width: 50%;
        background-color: #0f0f0f;
        padding: 90px;
        display: flex;
        align-items: center;
        color: var(--color-white);
    }

    &__title {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 45px;
    }

    &__phone {
        font-size: 24px;
        font-weight: 300;
        &>a {
            color: var(--color-white);
        }
        margin-bottom: 30px;
    }

    &__address {
        font-size: 18px;
        font-weight: 300;
        line-height: 28px;
        margin-bottom: 38px;
    }
}

@include mobile-small {
    .contacts {
        &__container {
            flex-direction: column;
        }
        &__left {
            width: 100%;
        }
        &__right {
            width: 100%;
        }
    }
}