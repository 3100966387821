.services {
    position: relative;
    background-image: url('../img/bg-smoke.jpg');
    background-size: cover;background-position: 0 60%;
    
    &__container {
        padding-top: 50px;
        padding-bottom: 200px;
    }

    &__title {
        color: var(--color-white);
        margin-bottom: 40px;
    }

    &__list {
        margin-bottom: 50px;
    }

    &__item {
        color: #f0f0f0;
        font-size: 22px;
        font-weight: 200;
        line-height: 43px;
        padding-left: 70px;
        display: flex;
        align-items: center;

        &::before {
            content: '';
            display: inline-block;
            width: 25px;
            height: 25px;
            background-image: url('../img/list-icon.svg');
            background-repeat: no-repeat;
            background-size: cover;
            margin-right: 15px;
        }
    }

    &__btn {
        padding-left: 70px;
    }

    &__bg-car{
        width: 785px;
        position: absolute;
        right: 5%;
        bottom: 21%;
    }   
}

@include tablet {
    .services__bg-car {
        right: -20%;
    }
}

@include mobile {
    .services__bg-car {
        right: -50%;
        top: 13%;
    }
}

@include mobile-small {
    .services {
        &__container {
            padding-bottom: 327px;
        }

        &__bg-car {
            width: 100%;
            bottom: 0;
            right: 0;
            top: unset;
        }
    }
}